.synth {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
}


.audio,
.visualiser,
.controls,
.canvas-shape {
    width: 100%;
    margin-bottom: 2rem;
}

.visualiser p {
    font-size: 1rem;
    margin: 1rem 0 0;
}

.synth .interactive-shape {
    display: flex;
    justify-content: center;
}

.synth .non-interactive-shape {
    top: 10px;
    position: absolute;
}

.synth button.reset-shape {
    padding: 1rem;
    background: #FF6577;
    color: #fff;
    font-size: large;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}

.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
}

.visualiser {
    margin-top: 2rem;
    z-index: -1;
    transition: transform 300ms ease-in-out;
    position: relative;
}

.canvas-shape-enter-active~.visualiser,
.canvas-shape-exit-active~.visualiser {
    transform: translateY(0);
}

.drag-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .synth {
        padding: 2rem 4rem;
    }
}

@media (max-width: 767px) {
    .synth .non-interactive-shape {
        top: -2rem;
        left: 0;
    }
}

@media (max-width: 425px) {
    .synth .non-interactive-shape {
        left: -5rem;
    }
}

@media (min-width: 1024px) {
    .synth {
        padding: 2rem 6rem;
    }
}