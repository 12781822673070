.drag-button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #1B1B1D;
    color: white;
    cursor: grab;
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 0px);
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    box-shadow: inset 0 0 0 1px #b3b3b3;
    transition: background-color 0.3s ease;
}

.drag-button:hover {
    background-color: #B3B3B3;
}

.drag-button:hover::after {
    content: 'Drag me';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    font-size: 12px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 3px;
}

.drag-box {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
}

.drag-button:active {
    cursor: grabbing;
}

@media (max-width: 768px) {
    .drag-box {
        padding: 0 0.5rem;
    }
}

.drag-box,
.drag-button {
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
}